<template>
    <div class="content-wrapper"
    v-touch:swipe="swipeHandler"
         v-on:keyup.left="keyupHandler"
         v-on:keyup.right="keyupHandler"
         tabindex="0">

            <slot></slot>
    </div>
</template>

<script>
    const DIR = {
        top: 'top',
        bottom: 'bottom'
    }
    export default {
        name: "PageScroll",
        // props: ['items', 'infinite', 'active'],
        data() {
            return {
                animate: false,
                animateReverse: false,
                animationMode: 'slide-up',
                animationStart: false,
            }
        },
        mounted() {
            document.addEventListener('wheel', this.scrollHandler)
        },
        beforeDestroy () {
            window.removeEventListener('wheel', this.scrollHandler)
        },
        methods: {
            log(e) {
                console.log('TEST LOG SCROLL')
                console.log(e)
            },
            swipeHandler(e) {
                console.log('swipe', e)
                // TODO: keep scroll of inner elements
                this.scroll('bottom')
            },
            keyupHandler(e) {
                console.log('keyup', e)
                // this.scroll(e)
            },
            scrollHandler(e) {
                console.log('scrollHandler')
                // console.log(this.$device.mobile)

                let direction = ''
                if (e.deltaY && e.deltaY < 0) {
                    direction = DIR.top
                } else {
                    direction = DIR.bottom
                }
                this.initEvent(direction)

            },
            initEvent(direction) {
                if (this.animate) {
                    return;
                }

                this.animate = true
                setTimeout(() => {
                    this.animate = false
                }, 1500)

                if (direction === DIR.bottom) {
                    console.log('Down')
                   this.$emit('scrollDown')
                }

                if (direction === DIR.top) {
                    console.log('Up')
                    this.$emit('scrollUp')
                }
            }
        }
    }
</script>

<style scoped>
</style>
