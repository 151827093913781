<template>
    <base-overlay key="'contact'" @close="closeOverlay">
        <page-scroll @scrollDown="scrollHandler(1)" @scrollUp="scrollHandler(-1)">
            <!--            <transition name="test" duration="800" @before-leave="shown=false" @after-appear="shown=true">-->
            <keep-alive>
                <router-view :key="$route.params.action" :shown="shown"></router-view>
            </keep-alive>
            <!--            </transition>-->
            <!--            <transition-group name="simple" mode="in-out" tag="div">-->
            <!--                <borish-contact v-for="(item, key) in data" :key="`block-${key}`" :info="item"-->
            <!--                                v-show="active===item.slug" :shown="transitionEntered&&active===item.slug"-->
            <!--                ></borish-contact>-->
            <!--                <router-view/>-->
            <!--            </transition-group>-->

        </page-scroll>
    </base-overlay>
</template>

<script>
    import store from "../store";
    import BaseOverlay from "../components/baseOverlay";
    import PageScroll from "../components/PageScroll";
    import overlayMixin from "../mixins/overlayMixin";

    export default {
        name: "Contact",
        mixins: [overlayMixin],
        components: {PageScroll, BaseOverlay},
        props: {action: String, transitionEntered: Boolean},
        data() {
            return {
                active: this.action,
                dataIndex: 0,
                shown: true
            }
        },

        computed: {
            data() {
                return store.contact
            }
        },

        watch: {
            action: [{
                handler: 'initialIndex',
                immediate: true
            },
                function () {
                    this.$nextTick(() => {
                        this.shown = true
                    })
                }
            ],
            data() {
                this.initialIndex()
            }
        },
        methods: {
            initialIndex() {
                this.dataIndex = Object.keys(this.data).indexOf(this.action);
                this.active = Object.keys(this.data)[this.dataIndex]
            },

            scrollHandler(i = 0) {
                if (!this.shown)
                    return;

                let length = Object.keys(this.data).length
                let index = this.dataIndex + i

                if (index < 0) {
                    index = length - 1
                }

                if (index >= length) {
                    index = 0
                }

                if (0 <= index < length) {
                    let next = this.data[Object.keys(this.data)[index]]

                    if (next) {
                        this.dataIndex = index
                        this.shown = false
                        // this.active = next.slug
                        setTimeout(() => {
                            this.$router.push({name: 'ContactDetails', params: {action: next.slug}})
                        }, 350)

                    }
                }
            },

        }
    }
</script>

<style scoped>
</style>